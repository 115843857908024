export function ThemeLogo() {
  return (
    <svg
      width="195"
      height="49"
      viewBox="0 0 195 49"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      className="theme_logo"
    >
      <g clipPath="url(#clip0_32_1837)">
        <g clipPath="url(#clip1_32_1837)">
          <path d="M0.00103192 30.436C-0.487111 40.3894 7.72916 48.5709 17.782 48.1936C26.4042 47.8688 33.5379 41.1058 34.1952 32.6044C34.4368 29.4951 33.823 26.553 32.5713 23.9692H44.9633L51.7297 14.3501H17.4292C8.45412 14.3453 0.436011 21.5763 0.00103192 30.436ZM18.0768 38.5219C13.2389 39.1237 9.16942 35.1023 9.77839 30.3214C10.194 27.0641 12.8571 24.4324 16.1532 24.0217C20.9912 23.4199 25.0606 27.4414 24.4517 32.2223C24.036 35.4843 21.373 38.1112 18.0768 38.5219Z" />
          <path d="M9.55103 0.0170898L2.77502 9.63616H55.016L61.7823 0.0170898H9.55103Z" />
        </g>
        <path d="M65.8811 14.5186H80.4967V18.8033H70.8073V23.6679H78.1804V27.8238H70.8073V36.0067H65.8811V14.5186Z" />
        <path d="M88.8505 34.5892C88.3285 35.1905 87.6652 35.6523 86.8605 35.9745C86.0775 36.2966 85.1966 36.4577 84.2179 36.4577C83.6742 36.4577 83.0978 36.3825 82.4888 36.2322C81.9016 36.0818 81.347 35.8349 80.825 35.4912C80.3248 35.1261 79.9006 34.6429 79.5527 34.0415C79.2264 33.4401 79.0633 32.6884 79.0633 31.7864C79.0633 30.9058 79.2373 30.1219 79.5853 29.4346C79.955 28.7473 80.4553 28.1782 81.086 27.7271C81.7167 27.2546 82.4562 26.9003 83.3044 26.664C84.1744 26.4278 85.1096 26.3096 86.1101 26.3096C86.6538 26.3096 87.1976 26.3204 87.7413 26.3419C88.3068 26.3633 88.6765 26.3955 88.8505 26.4385V26.1163C88.8505 25.7297 88.7635 25.4076 88.5895 25.1499C88.4373 24.8707 88.2198 24.6451 87.9371 24.4733C87.6761 24.28 87.3716 24.1512 87.0236 24.0867C86.6756 24.0008 86.3059 23.9579 85.9144 23.9579C84.8704 23.9579 84.0004 24.0867 83.3044 24.3445C82.6302 24.6022 82.0538 24.8921 81.5754 25.2143L79.6505 22.3793C79.9768 22.143 80.3465 21.9068 80.7597 21.6705C81.173 21.4343 81.6623 21.2195 82.2278 21.0262C82.7933 20.8329 83.4241 20.6826 84.12 20.5752C84.8378 20.4463 85.6534 20.3819 86.5668 20.3819C88.894 20.3819 90.6122 21.0047 91.7215 22.2504C92.8307 23.4746 93.3853 25.1284 93.3853 27.2117V36.0067H88.981L88.8505 34.5892ZM88.8505 29.3379C88.7418 29.3165 88.5352 29.295 88.2307 29.2735C87.9262 29.2306 87.5782 29.2091 87.1867 29.2091C85.8817 29.2091 84.9574 29.3916 84.4137 29.7568C83.8699 30.1219 83.598 30.6373 83.598 31.3031C83.598 32.5488 84.3593 33.1717 85.8817 33.1717C86.2515 33.1717 86.6103 33.118 86.9583 33.0106C87.3281 32.8817 87.6434 32.7099 87.9044 32.4951C88.1872 32.2803 88.4155 32.0119 88.5895 31.6897C88.7635 31.3676 88.8505 30.9917 88.8505 30.5622V29.3379Z" />
        <path d="M95.4944 20.8973H99.9965L100.127 22.5404C100.627 21.939 101.28 21.4557 102.084 21.0906C102.911 20.7255 103.77 20.543 104.662 20.543C106.423 20.543 107.815 21.0584 108.838 22.0893C109.882 23.1202 110.404 24.6451 110.404 26.664V36.0067H105.706V27.3083C105.706 26.4707 105.488 25.8049 105.053 25.3109C104.64 24.817 104.053 24.57 103.292 24.57C102.77 24.57 102.313 24.6666 101.921 24.8599C101.53 25.0317 101.204 25.268 100.943 25.5687C100.682 25.8693 100.486 26.2237 100.355 26.6318C100.225 27.0184 100.16 27.4372 100.16 27.8882V36.0067H95.4944V20.8973Z" />
        <path d="M109.893 14.5186H115.341L120.659 30.2078L125.846 14.5186H131.066L123.073 36.0067H117.886L109.893 14.5186Z" />
        <path d="M144.053 34.3637C143.879 34.471 143.618 34.6536 143.27 34.9113C142.922 35.1476 142.466 35.3838 141.9 35.6201C141.357 35.8563 140.704 36.0604 139.943 36.2322C139.182 36.4255 138.312 36.5221 137.333 36.5221C136.028 36.5221 134.853 36.3288 133.809 35.9422C132.766 35.5342 131.874 34.9758 131.134 34.267C130.417 33.5582 129.862 32.7099 129.47 31.7219C129.101 30.7125 128.916 29.6064 128.916 28.4037C128.916 27.3513 129.09 26.3419 129.438 25.3754C129.808 24.3874 130.33 23.5283 131.004 22.7981C131.678 22.0679 132.505 21.488 133.483 21.0584C134.462 20.6074 135.571 20.3819 136.811 20.3819C138.051 20.3819 139.138 20.5967 140.073 21.0262C141.009 21.4557 141.792 22.0571 142.422 22.8303C143.075 23.582 143.564 24.4948 143.89 25.5687C144.217 26.6211 144.38 27.7808 144.38 29.048V29.8212H133.483C133.505 30.6373 133.864 31.3568 134.56 31.9797C135.278 32.581 136.256 32.8817 137.496 32.8817C138.714 32.8817 139.704 32.7099 140.465 32.3663C141.226 32.0011 141.781 31.6897 142.129 31.432L144.053 34.3637ZM139.943 26.8573C139.943 26.5351 139.878 26.213 139.747 25.8908C139.617 25.5472 139.421 25.2358 139.16 24.9566C138.899 24.6774 138.573 24.4518 138.181 24.28C137.79 24.0867 137.322 23.9901 136.778 23.9901C136.235 23.9901 135.756 24.0867 135.343 24.28C134.93 24.4518 134.582 24.6774 134.299 24.9566C134.038 25.2358 133.831 25.5472 133.679 25.8908C133.548 26.213 133.483 26.5351 133.483 26.8573H139.943Z" />
        <path d="M146.407 20.8973H150.91L151.073 22.7981C151.464 22.2826 151.997 21.7886 152.671 21.3161C153.367 20.8436 154.259 20.6074 155.346 20.6074C155.76 20.6074 156.108 20.6289 156.39 20.6718C156.695 20.7148 156.989 20.7899 157.271 20.8973L156.521 24.9888C156.042 24.8384 155.477 24.7633 154.825 24.7633C153.715 24.7633 152.813 25.0962 152.117 25.762C151.442 26.4063 151.105 27.3298 151.105 28.5325V36.0067H146.407V20.8973Z" />
        <path d="M159.802 31.3353C160.302 31.7864 160.955 32.1622 161.76 32.4629C162.586 32.7636 163.38 32.9139 164.141 32.9139C164.837 32.9139 165.414 32.828 165.87 32.6562C166.327 32.4844 166.555 32.1515 166.555 31.6575C166.555 31.4642 166.501 31.2924 166.392 31.142C166.305 30.9702 166.131 30.8199 165.87 30.691C165.609 30.5407 165.24 30.3903 164.761 30.24C164.304 30.0897 163.717 29.9178 162.999 29.7245C162.412 29.5742 161.825 29.3809 161.238 29.1446C160.672 28.8869 160.161 28.5755 159.704 28.2104C159.269 27.8453 158.911 27.405 158.628 26.8895C158.367 26.3741 158.236 25.7727 158.236 25.0854C158.236 24.3767 158.399 23.7431 158.726 23.1847C159.052 22.6048 159.498 22.1108 160.063 21.7027C160.65 21.2732 161.336 20.951 162.119 20.7363C162.923 20.5 163.782 20.3819 164.696 20.3819C166.001 20.3819 167.143 20.5322 168.121 20.8329C169.1 21.1121 170.003 21.5309 170.829 22.0893L168.839 25.1499C168.339 24.8277 167.763 24.5378 167.11 24.28C166.479 24.0223 165.762 23.8934 164.957 23.8934C164.239 23.8934 163.717 24.0008 163.391 24.2156C163.065 24.4089 162.902 24.6774 162.902 25.021C162.902 25.515 163.239 25.8801 163.913 26.1163C164.587 26.3526 165.501 26.6211 166.653 26.9217C168.219 27.3513 169.35 27.9419 170.046 28.6936C170.764 29.4453 171.123 30.444 171.123 31.6897C171.123 32.5273 170.927 33.2468 170.536 33.8482C170.166 34.4496 169.655 34.9543 169.002 35.3623C168.372 35.7489 167.643 36.0389 166.816 36.2322C166.012 36.4255 165.185 36.5221 164.337 36.5221C162.967 36.5221 161.673 36.3396 160.455 35.9745C159.237 35.6093 158.247 35.0509 157.486 34.2992L159.802 31.3353Z" />
        <path d="M187.775 34.3637C187.601 34.471 187.34 34.6536 186.992 34.9113C186.644 35.1476 186.187 35.3838 185.622 35.6201C185.078 35.8563 184.425 36.0604 183.664 36.2322C182.903 36.4255 182.033 36.5221 181.054 36.5221C179.749 36.5221 178.575 36.3288 177.531 35.9422C176.487 35.5342 175.595 34.9758 174.856 34.267C174.138 33.5582 173.583 32.7099 173.192 31.7219C172.822 30.7125 172.637 29.6064 172.637 28.4037C172.637 27.3513 172.811 26.3419 173.159 25.3754C173.529 24.3874 174.051 23.5283 174.725 22.7981C175.399 22.0679 176.226 21.488 177.205 21.0584C178.183 20.6074 179.293 20.3819 180.532 20.3819C181.772 20.3819 182.86 20.5967 183.795 21.0262C184.73 21.4557 185.513 22.0571 186.144 22.8303C186.796 23.582 187.286 24.4948 187.612 25.5687C187.938 26.6211 188.101 27.7808 188.101 29.048V29.8212H177.205C177.226 30.6373 177.585 31.3568 178.281 31.9797C178.999 32.581 179.978 32.8817 181.217 32.8817C182.435 32.8817 183.425 32.7099 184.186 32.3663C184.947 32.0011 185.502 31.6897 185.85 31.432L187.775 34.3637ZM183.664 26.8573C183.664 26.5351 183.599 26.213 183.468 25.8908C183.338 25.5472 183.142 25.2358 182.881 24.9566C182.62 24.6774 182.294 24.4518 181.903 24.28C181.511 24.0867 181.043 23.9901 180.5 23.9901C179.956 23.9901 179.477 24.0867 179.064 24.28C178.651 24.4518 178.303 24.6774 178.02 24.9566C177.759 25.2358 177.553 25.5472 177.4 25.8908C177.27 26.213 177.205 26.5351 177.205 26.8573H183.664Z" />
      </g>
      <defs>
        <clipPath id="clip0_32_1837">
          <rect width="194.824" height="48.3124" />
        </clipPath>
        <clipPath id="clip1_32_1837">
          <rect
            width="193.768"
            height="48.1861"
            transform="translate(-0.0186768 0.0170898)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function MovingCircleIcon() {
  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className="moving_bar"
    >
      <circle cx="50" cy="50" r="46" />
    </svg>
  );
}
export function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      className="iconColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" y1="12" x2="20" y2="12" />
      <line x1="4" y1="6" x2="20" y2="6" />
      <line x1="4" y1="18" x2="20" y2="18" />
    </svg>
  );
}
export function UploadIcon() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2148 0.879557C10.2148 0.423828 9.86979 0.0722652 9.41406 0.0722652L0.983072 0.0722656C0.520833 0.0722656 0.175781 0.423828 0.175781 0.879558C0.175781 1.3418 0.520833 1.69336 0.983072 1.69336L5.02604 1.69336C4.85677 1.72591 4.70052 1.81706 4.57682 1.94076L0.553385 5.87305C0.371093 6.04883 0.286458 6.25716 0.286458 6.47201C0.286458 6.95378 0.625 7.29232 1.08073 7.29232C1.32812 7.29232 1.52995 7.18815 1.6862 7.0319L2.91667 5.82096L4.4401 4.14128L4.35547 5.48893L4.35547 11.0684C4.35547 11.5827 4.69401 11.9277 5.19531 11.9277C5.6901 11.9277 6.02865 11.5827 6.02865 11.0684L6.02865 5.48893L5.95052 4.13477L7.46745 5.82096L8.69792 7.0319C8.85417 7.18815 9.05599 7.29232 9.30338 7.29232C9.75911 7.29232 10.0977 6.95378 10.0977 6.472C10.0977 6.25716 10.013 6.04883 9.83073 5.87305L5.8138 1.94076C5.6901 1.81706 5.53385 1.72591 5.35807 1.69336L9.41406 1.69336C9.86979 1.69336 10.2148 1.3418 10.2148 0.879557Z"
        fill="#141029"
      />
    </svg>
  );
}
export function CrossBtn() {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79883 8.28321C8.04785 8.0293 8.04785 7.61426 7.80371 7.37012L4.91797 4.48438L7.80371 1.59375C8.04785 1.34961 8.05273 0.929692 7.79883 0.680669C7.5498 0.431645 7.12988 0.431645 6.88574 0.675786L4 3.56153L1.10938 0.675786C0.875 0.436528 0.445312 0.426762 0.196289 0.680669C-0.0527344 0.929692 -0.0478516 1.35938 0.191406 1.59375L3.07715 4.48438L0.191406 7.37012C-0.0478516 7.60938 -0.0527344 8.03418 0.196289 8.28321C0.450195 8.53223 0.875 8.52735 1.10938 8.29297L4 5.40235L6.88574 8.28809C7.12988 8.53223 7.5498 8.53223 7.79883 8.28321Z"
        fill="#E0E0FF"
        fill-opacity="0.24"
      />
    </svg>
  );
}
export function DownArrow() {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99609 8.75C8.33203 8.75 8.62109 8.61719 8.87891 8.35938L14.7305 2.35938C14.9336 2.15625 15.043 1.90625 15.043 1.60938C15.043 1 14.5586 0.515625 13.957 0.515625C13.6602 0.515625 13.3789 0.632812 13.1602 0.851562L8.00391 6.16406L2.83984 0.851562C2.62109 0.640625 2.34766 0.515625 2.04297 0.515625C1.44141 0.515625 0.957031 1 0.957031 1.60938C0.957031 1.90625 1.06641 2.15625 1.26953 2.36719L7.12109 8.35938C7.39453 8.625 7.66797 8.75 7.99609 8.75Z"
        fill="#141029"
        fill-opacity="0.4"
      />
    </svg>
  );
}

export function AudioIcon() {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 522.234 522.234"
    >
      <g>
        <g>
          <g>
            <path
              d="M346.35,41.163c-10.855-10.367-25.076-16.078-40.045-16.078c-10.832,0-21.445,3.063-30.689,8.857l-159.161,99.761H58.354
				c-31.9,0-57.854,25.952-57.854,57.853v138.918c0,31.9,25.953,57.854,57.854,57.854h57.773l159.487,99.965
				c9.244,5.795,19.857,8.857,30.691,8.857c14.969,0,29.189-5.71,40.047-16.078c5.543-5.293,9.908-11.525,12.979-18.523
				c3.227-7.353,4.861-15.184,4.861-23.275V261.22v-0.205V82.963c0-8.094-1.635-15.925-4.861-23.278
				C356.26,52.689,351.893,46.457,346.35,41.163z M321.354,261.015v0.205v178.052c0,8.803-7.227,15.037-15.049,15.037
				c-2.664,0-5.398-0.724-7.939-2.316L137.222,350.989c-5.736-3.595-12.368-5.502-19.138-5.502h-59.73
				c-8.292,0-15.014-6.722-15.014-15.014V191.556c0-8.291,6.722-15.013,15.014-15.013h60.059c6.77,0,13.4-1.907,19.137-5.502
				L298.365,70.242c2.541-1.593,5.273-2.316,7.939-2.316c7.822,0,15.049,6.236,15.049,15.038V261.015z"
            />
            <path
              d="M306.305,497.649c-10.929,0-21.634-3.089-30.957-8.934l-159.365-99.889H58.354C26.177,388.827,0,362.649,0,330.474
				V191.556c0-32.176,26.177-58.353,58.354-58.353h57.958L275.35,33.519c9.325-5.844,20.029-8.934,30.955-8.934
				c15.096,0,29.44,5.759,40.391,16.216c5.591,5.34,9.995,11.625,13.093,18.683c3.254,7.415,4.903,15.314,4.903,23.479v356.309
				c0,8.163-1.649,16.062-4.903,23.477c-3.099,7.062-7.503,13.348-13.091,18.684C335.746,491.891,321.401,497.649,306.305,497.649z
				 M58.354,134.203C26.729,134.203,1,159.931,1,191.556v138.918c0,31.625,25.729,57.354,57.354,57.354h57.917l0.122,0.076
				l159.487,99.965c9.164,5.745,19.685,8.781,30.426,8.781c14.838,0,28.938-5.661,39.701-15.939
				c5.493-5.245,9.821-11.423,12.866-18.363c3.198-7.287,4.819-15.05,4.819-23.074V82.963c0-8.025-1.621-15.79-4.819-23.077
				c-3.044-6.937-7.373-13.114-12.868-18.362c-10.763-10.279-24.861-15.939-39.699-15.939c-10.738,0-21.259,3.037-30.424,8.781
				l-159.283,99.837H58.354z M306.305,454.809c-2.87,0-5.708-0.827-8.205-2.393L136.956,351.413
				c-5.664-3.55-12.189-5.426-18.872-5.426h-59.73c-8.554,0-15.514-6.959-15.514-15.514V191.556c0-8.554,6.959-15.513,15.514-15.513
				h60.059c6.682,0,13.207-1.876,18.871-5.426L298.1,69.818c2.497-1.565,5.335-2.393,8.205-2.393c8.573,0,15.549,6.97,15.549,15.538
				v356.308C321.854,447.839,314.878,454.809,306.305,454.809z M58.354,177.043c-8.003,0-14.514,6.51-14.514,14.513v138.918
				c0,8.003,6.511,14.514,14.514,14.514h59.73c6.871,0,13.58,1.929,19.403,5.578l161.144,101.003c2.338,1.466,4.991,2.24,7.674,2.24
				c8.022,0,14.549-6.521,14.549-14.537V82.963c0-8.016-6.526-14.538-14.549-14.538c-2.683,0-5.336,0.774-7.674,2.24
				L137.814,171.465c-5.824,3.649-12.533,5.578-19.402,5.578H58.354z"
            />
          </g>
          <g>
            <path
              d="M424.273,156.536c-5.266-10.594-18.125-14.911-28.715-9.646c-10.594,5.266-14.912,18.123-9.646,28.716
				c12.426,24.995,18.992,54.604,18.992,85.626c0,31.506-6.754,61.487-19.533,86.705c-5.348,10.553-1.129,23.442,9.424,28.79
				c3.104,1.572,6.408,2.317,9.664,2.317c7.816,0,15.35-4.294,19.125-11.742c15.807-31.191,24.16-67.869,24.16-106.07
				C447.746,223.628,439.629,187.424,424.273,156.536z"
            />
            <path
              d="M404.459,379.545c-3.456,0-6.784-0.798-9.89-2.371c-10.782-5.464-15.108-18.681-9.645-29.462
				c12.744-25.147,19.479-55.052,19.479-86.479c0-30.948-6.549-60.48-18.939-85.404c-2.606-5.243-3.016-11.188-1.15-16.738
				c1.864-5.55,5.778-10.042,11.021-12.648c3.064-1.523,6.341-2.296,9.739-2.296c8.388,0,15.916,4.662,19.646,12.167
				c15.391,30.959,23.524,67.239,23.522,104.919c0,38.28-8.373,75.037-24.214,106.296
				C420.273,374.94,412.773,379.545,404.459,379.545z M405.075,145.146c-3.242,0-6.369,0.737-9.294,2.191
				c-5.004,2.487-8.74,6.774-10.52,12.071c-1.779,5.297-1.39,10.97,1.098,15.974c12.459,25.062,19.045,54.748,19.045,85.849
				c0,31.584-6.773,61.645-19.587,86.931c-5.215,10.29-1.086,22.904,9.203,28.118c2.965,1.502,6.141,2.264,9.438,2.264
				c7.936,0,15.094-4.395,18.679-11.468c15.771-31.12,24.106-67.721,24.106-105.845c0.002-37.526-8.096-73.652-23.418-104.474
				C420.266,149.596,413.081,145.146,405.075,145.146z"
            />
          </g>
          <g>
            <path
              d="M456.547,88.245c-10.594,5.266-14.912,18.122-9.646,28.716c20.932,42.105,31.994,91.864,31.994,143.897
				c0,52.847-11.381,103.237-32.912,145.727c-5.348,10.552-1.129,23.441,9.424,28.788c3.104,1.573,6.408,2.318,9.666,2.318
				c7.814,0,15.35-4.294,19.123-11.743c24.559-48.462,37.539-105.549,37.539-165.09c0-58.615-12.611-114.968-36.473-162.968
				C479.996,87.297,467.141,82.977,456.547,88.245z"
            />
            <path
              d="M465.072,438.19c-3.458,0-6.787-0.798-9.893-2.372c-5.223-2.646-9.102-7.168-10.923-12.732s-1.367-11.506,1.279-16.728
				c21.496-42.42,32.858-92.733,32.858-145.501c0-51.958-11.045-101.64-31.941-143.674c-5.381-10.824-0.952-24.006,9.871-29.386
				c3.065-1.524,6.343-2.297,9.742-2.297c8.386,0,15.912,4.663,19.643,12.167c23.896,48.067,36.525,104.498,36.525,163.19
				c0,59.619-12.999,116.785-37.593,165.315C480.887,433.586,473.388,438.19,465.072,438.19z M466.066,86.5
				c-3.243,0-6.371,0.738-9.297,2.193c-5.004,2.487-8.74,6.774-10.52,12.071s-1.389,10.97,1.098,15.974
				c20.966,42.172,32.047,92.008,32.047,144.12c0,52.924-11.399,103.394-32.966,145.952c-2.526,4.984-2.96,10.654-1.222,15.965
				s5.44,9.626,10.425,12.151c2.965,1.503,6.141,2.265,9.44,2.265c7.937,0,15.094-4.395,18.677-11.469
				c24.523-48.392,37.485-105.401,37.485-164.864c0-58.54-12.594-114.816-36.42-162.745C481.253,90.949,474.069,86.5,466.066,86.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function VideoIcon() {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 587.909 587.909"
    >
      <g>
        <g>
          <g>
            <path
              d="M210.604,460.299c10.876,10.205,25.07,15.824,39.965,15.824c14.448,0,28.33-5.404,39.086-15.216l135.887-123.952
       c12.048-10.99,18.959-26.646,18.959-42.954c0-16.308-6.91-31.964-18.959-42.955L289.656,127.095
       c-10.758-9.812-24.638-15.216-39.086-15.216c-14.896,0-29.089,5.619-39.965,15.823c-5.68,5.329-10.155,11.629-13.3,18.727
       c-3.298,7.442-4.97,15.389-4.97,23.62v247.904c0,8.231,1.672,16.179,4.971,23.621
       C200.45,448.671,204.924,454.971,210.604,460.299z M235.174,170.049c0-9.17,7.511-15.33,15.396-15.33
       c3.546,0,7.168,1.247,10.216,4.026l135.887,123.952c6.651,6.068,6.651,16.54,0,22.607L260.785,429.257
       c-3.048,2.78-6.669,4.026-10.216,4.026c-7.884,0-15.396-6.161-15.396-15.33L235.174,170.049L235.174,170.049z"
            />
            <path
              d="M250.569,476.623c-15.024,0-29.339-5.668-40.308-15.96c-5.729-5.374-10.242-11.729-13.414-18.888
       c-3.327-7.506-5.014-15.521-5.014-23.823V170.048c0-8.302,1.687-16.316,5.013-23.822c3.172-7.159,7.686-13.514,13.415-18.889
       c10.969-10.291,25.284-15.958,40.308-15.958c14.572,0,28.573,5.45,39.423,15.347l135.887,123.951
       c12.152,11.085,19.122,26.876,19.122,43.325c0,16.447-6.97,32.238-19.122,43.323L289.992,461.276
       C279.143,471.173,265.142,476.623,250.569,476.623z M250.569,112.378c-14.769,0-28.84,5.571-39.623,15.688
       c-5.631,5.283-10.067,11.529-13.186,18.564c-3.269,7.377-4.927,15.256-4.927,23.417v247.904c0,8.161,1.658,16.04,4.928,23.419
       c3.118,7.035,7.553,13.28,13.185,18.563c10.783,10.117,24.854,15.688,39.623,15.688c14.323,0,28.084-5.357,38.749-15.085
       l135.887-123.952c11.945-10.896,18.796-26.418,18.796-42.585c0-16.167-6.851-31.689-18.796-42.585L289.319,127.464
       C278.653,117.736,264.892,112.378,250.569,112.378z M250.569,433.783c-9.818,0-15.896-8.217-15.896-15.83V170.049
       c0-7.613,6.078-15.83,15.896-15.83c3.922,0,7.571,1.438,10.553,4.157l135.887,123.952c3.274,2.987,5.151,7.242,5.151,11.674
       c0,4.432-1.878,8.686-5.151,11.672L261.122,429.626C258.141,432.346,254.491,433.783,250.569,433.783z M235.673,170.049v247.904
       c0,7.132,5.695,14.83,14.896,14.83c3.669,0,7.085-1.347,9.879-3.896l135.888-123.952c3.066-2.798,4.825-6.783,4.825-10.934
       c0-4.152-1.759-8.138-4.825-10.936L260.448,159.115c-2.794-2.549-6.21-3.896-9.879-3.896
       C243.248,155.219,235.674,160.766,235.673,170.049L235.673,170.049z"
            />
          </g>
          {/* <g>
            <path
              d="M86.452,501.459c26.937,26.937,58.315,48.089,93.265,62.871c36.207,15.314,74.642,23.079,114.239,23.079
       s78.033-7.766,114.239-23.079c34.95-14.782,66.328-35.935,93.265-62.871s48.09-58.316,62.872-93.266
       c15.314-36.206,23.078-74.642,23.078-114.239s-7.765-78.033-23.078-114.239c-14.782-34.949-35.936-66.328-62.872-93.264
       s-58.314-48.091-93.265-62.873C371.988,8.264,333.552,0.5,293.955,0.5c-39.597,0-78.032,7.765-114.239,23.078
       c-34.95,14.782-66.328,35.937-93.265,62.873c-26.937,26.937-48.09,58.315-62.873,93.265C8.265,215.923,0.5,254.358,0.5,293.955
       c0,39.598,7.765,78.032,23.079,114.239C38.361,443.144,59.515,474.522,86.452,501.459z M293.955,43.34
       c138.411,0,250.615,112.204,250.615,250.615c0,138.41-112.204,250.614-250.615,250.614S43.34,432.365,43.34,293.955
       C43.34,155.544,155.544,43.34,293.955,43.34z"
            />
            <path
              d="M293.955,587.909c-39.665,0-78.166-7.778-114.434-23.118c-35.01-14.808-66.442-35.997-93.423-62.979
       c-26.982-26.982-48.172-58.414-62.979-93.424C7.779,372.119,0,333.618,0,293.955c0-39.665,7.778-78.166,23.118-114.434
       c14.808-35.009,35.997-66.442,62.979-93.424c26.984-26.984,58.416-48.173,93.423-62.98C215.79,7.778,254.291,0,293.955,0
       c39.666,0,78.167,7.778,114.433,23.118c35.008,14.806,66.439,35.996,93.424,62.98c26.982,26.982,48.172,58.415,62.979,93.423
       c15.339,36.269,23.117,74.77,23.117,114.434c0,39.667-7.778,78.167-23.117,114.433c-14.809,35.011-35.997,66.443-62.979,93.425
       c-26.981,26.982-58.414,48.171-93.424,62.979C372.119,580.131,333.618,587.909,293.955,587.909z M293.955,1
       c-39.529,0-77.899,7.751-114.044,23.039c-34.888,14.756-66.213,35.874-93.106,62.766c-26.891,26.891-48.008,58.216-62.766,93.106
       C8.752,216.055,1,254.425,1,293.955C1,333.483,8.752,371.854,24.04,408c14.757,34.89,35.875,66.215,62.766,93.105
       c26.89,26.89,58.215,48.007,93.106,62.764c36.144,15.288,74.514,23.04,114.044,23.04c39.529,0,77.898-7.752,114.044-23.04
       c34.89-14.757,66.215-35.873,93.105-62.764s48.008-58.216,62.765-93.106c15.288-36.143,23.039-74.512,23.039-114.044
       c0-39.53-7.751-77.9-23.039-114.044c-14.757-34.889-35.874-66.214-62.765-93.105C474.212,59.912,442.887,38.794,408,24.039
       C371.856,8.751,333.486,1,293.955,1z M293.955,545.069c-67.075,0-130.136-26.12-177.565-73.55S42.84,361.03,42.84,293.955
       s26.121-130.136,73.55-177.565s110.49-73.55,177.565-73.55c67.075,0,130.135,26.121,177.565,73.55s73.55,110.49,73.55,177.565
       S518.95,424.09,471.52,471.52S361.03,545.069,293.955,545.069z M293.955,43.84c-66.808,0-129.617,26.017-176.858,73.257
       c-47.24,47.241-73.257,110.05-73.257,176.858c0,66.808,26.017,129.617,73.257,176.857c47.241,47.24,110.05,73.257,176.858,73.257
       s129.618-26.017,176.858-73.257s73.257-110.05,73.257-176.857c0-66.808-26.017-129.617-73.257-176.858
       C423.573,69.857,360.763,43.84,293.955,43.84z"
            />
          </g> */}
        </g>
      </g>
    </svg>
  );
}

import toast from "react-hot-toast";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import warnToast from "../../../assets/images/warnToast.svg";
import { CrossBtn } from "../../../assets/images/svg/SvgImages";
class toster {
  success(massage) {
    toast.custom((t) => (
      <div className="successToast">
        <img src={successToast} alt="Icon" />
        <div className="textSection">
          {/* <h5>Congrats!</h5> */}
          <p>{massage}</p>
          {/* <button onClick={() => toast.dismiss(t.id)}>
            <CrossBtn />
          </button> */}
        </div>
      </div>
    ));
  }
  warn(massage) {
    toast.custom((t) => (
      <div className="successToast">
        <img src={warnToast} alt="Icon" />
        <div className="textSection">
          {/* <h5>Warning!</h5> */}
          <p>{massage}</p>
          {/* <button onClick={() => toast.dismiss(t.id)}>
            <CrossBtn />
          </button> */}
        </div>
      </div>
    ));
  }
  error(massage) {
    toast.custom((t) => (
      <div className="successToast">
        <img src={errorToast} alt="Icon" />
        <div className="textSection">
          {/* <h5>Ooops…</h5> */}
          <p>{massage}</p>
          {/* <button onClick={() => toast.dismiss(t.id)}>
            <CrossBtn />
          </button> */}
        </div>
      </div>
    ));
  }
}

export const toasts = new toster();
